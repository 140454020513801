import './bootstrap';
import Splide from '@splidejs/splide';
import 'flowbite';

window.Splide = Splide;

import { browserTracingIntegration, BrowserClient, browserProfilingIntegration, makeFetchTransport, defaultStackParser, getCurrentScope } from "@sentry/browser";

const sentry = new BrowserClient({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_APP_ENV,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [
        browserTracingIntegration({
            enableInp: true,
        }),
        browserProfilingIntegration(),
    ],
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['aimage.ruanglampu.com', 'ws.ruanglampu.com'],
});

getCurrentScope().setClient(sentry);
sentry.init();

